import React from "react";
import ".././App.css";
import ".././tableau.css";
// Load Components
// import SoCal from "../tableauTables/SoCal";
// import NorCal from "../tableauTables/NorCal";
import CASalesReport from "../tableauTables/CASalesReport";
// import Header from "../components/common/Header";
import { NonEmbeddedHeader } from "av8-ui";
import Grid from '@material-ui/core/Grid';

export default function Dashboard() {
    return (
        <div>
            <NonEmbeddedHeader 
              centralText="California Sales Report" 
              menuOptions={[{text: 'Menu' }, { text: 'Log out' }]} 
            />
            {/* <Grid item className={classes.headerTextWrapper}>
                <Typography className={classes.headerText}>
                    {headerNavigationTitle}
                </Typography>
            </Grid> */}
            {/* <DesktopHeader 
              withRightMenu="true"
            /> */}
            {/* <h1 className='Title-header'>California Sales Report</h1> */}
            <h3 className='Center-Dashboard'>
                <CASalesReport />
            </h3>
        </div>
    );
}
